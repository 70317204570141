/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import AuthLayout from "./layouts/Auth";
import ErrorLayout from "./layouts/Error";
import DashboardLayout from "./layouts/Dashboard";
import DashboardAnalyticsView from "./views/DashboardAnalytics";
import DashboardDefaultView from "./views/DashboardDefault";
import OverviewView from "./views/Overview";
import PresentationView from "./views/Presentation";

export default [
    {
        path: "/",
        exact: true,
        component: () =>
            localStorage.getItem("loggedIn") ? (
                <Redirect to="/dashboard" />
            ) : (
                <Redirect to="/auth/login" />
            )
    },
    {
        path: "/auth",
        component: AuthLayout,
        routes: [
            {
                path: "/auth/login",
                exact: true,
                component: lazy(() => import("src/views/Login"))
            },
            {
                path: "/auth/register",
                exact: true,
                component: lazy(() => import("src/views/Register"))
            },
            {
                component: () => <Redirect to="/errors/error-404" />
            }
        ]
    },
    {
        path: "/errors",
        component: ErrorLayout,
        routes: [
            {
                path: "/errors/error-401",
                exact: true,
                component: lazy(() => import("src/views/Error401"))
            },
            {
                path: "/errors/error-404",
                exact: true,
                component: lazy(() => import("src/views/Error404"))
            },
            {
                path: "/errors/error-500",
                exact: true,
                component: lazy(() => import("src/views/Error500"))
            },
            {
                component: () => <Redirect to="/errors/error-404" />
            }
        ]
    },
    {
        route: "*",
        component: DashboardLayout,
        routes: [
            {
                path: "/printpersediaan",
                exact: true,
                component: lazy(() =>
                    import("src/views/Persediaan/PrintPersediaan")
                )
            },
            {
                path: "/calendar",
                exact: true,
                component: lazy(() => import("src/views/Calendar"))
            },
            {
                path: "/changelog",
                exact: true,
                component: lazy(() => import("src/views/Changelog"))
            },
            {
                path: "/chat",
                exact: true,
                component: lazy(() => import("src/views/Chat"))
            },
            {
                path: "/chat/:id",
                exact: true,
                component: lazy(() => import("src/views/Chat"))
            },
            {
                path: "/components/buttons",
                exact: true,
                component: lazy(() => import("src/views/Buttons"))
            },
            {
                path: "/components/cards",
                exact: true,
                component: lazy(() => import("src/views/Cards"))
            },
            {
                path: "/components/chips",
                exact: true,
                component: lazy(() => import("src/views/Chips"))
            },
            {
                path: "/components/forms",
                exact: true,
                component: lazy(() => import("src/views/Forms"))
            },
            {
                path: "/components/lists",
                exact: true,
                component: lazy(() => import("src/views/Lists"))
            },
            {
                path: "/components/modals",
                exact: true,
                component: lazy(() => import("src/views/Modals"))
            },
            {
                path: "/components/typography",
                exact: true,
                component: lazy(() => import("src/views/Typography"))
            },
            {
                path: "/dashboards/analytics",
                exact: true,
                component: DashboardAnalyticsView
            },
            {
                path: "/dashboards/default",
                exact: true,
                component: DashboardDefaultView
            },
            {
                path: "/invoices/:id",
                exact: true,
                component: lazy(() => import("src/views/InvoiceDetails"))
            },
            {
                path: "/kanban-board",
                exact: true,
                component: lazy(() => import("src/views/KanbanBoard"))
            },
            {
                path: "/mail",
                exact: true,
                component: lazy(() => import("src/views/Mail"))
            },
            {
                path: "/penjualan/tipecustomer",
                exact: true,
                component: lazy(() =>
                    import("src/views/Penjualan/MasterTipeCustomer")
                )
            },
            {
                path: "/penjualan/tipecustomer/:id",
                exact: true,
                component: lazy(() =>
                    import("src/views/Penjualan/MasterTipeCustomerDetail")
                )
            },
            {
                path: "/penjualan/tipecustomer/:id/:tab",
                exact: true,
                component: lazy(() =>
                    import("src/views/Penjualan/MasterTipeCustomerDetail")
                )
            },
            {
                path: "/penjualan/customer",
                exact: true,
                component: lazy(() =>
                    import("src/views/Penjualan/MasterCustomer")
                )
            },
            {
                path: "/penjualan/customer/:id",
                exact: true,
                component: lazy(() =>
                    import("src/views/Penjualan/MasterCustomerDetail")
                )
            },
            {
                path: "/penjualan/customer/:id/:tab",
                exact: true,
                component: lazy(() =>
                    import("src/views/Penjualan/MasterCustomerDetail")
                )
            },
            {
                path: "/penjualan/pesananpenjualan",
                exact: true,
                component: lazy(() =>
                    import("src/views/Penjualan/PesananPenjualan")
                )
            },
            {
                path: "/penjualan/pesananpenjualan/create",
                exact: true,
                component: lazy(() =>
                    import("src/views/Penjualan/PesananPenjualanCreate")
                )
            },
            {
                path: "/penjualan/pesananpenjualan/:id",
                exact: true,
                component: lazy(() =>
                    import("src/views/Penjualan/PesananPenjualanDetail")
                )
            },
            {
                path: "/penjualan/pesananpenjualan/:id/:tab",
                exact: true,
                component: lazy(() =>
                    import("src/views/Penjualan/PesananPenjualanDetail")
                )
            },
            {
                path: "/penjualan/pengirimanbarang",
                exact: true,
                component: lazy(() =>
                    import("src/views/Penjualan/PengirimanBarang")
                )
            },
            {
                path: "/penjualan/pengirimanbarang/create",
                exact: true,
                component: lazy(() =>
                    import("src/views/Penjualan/PengirimanBarangCreate")
                )
            },
            {
                path: "/penjualan/pengirimanbarang/:id",
                exact: true,
                component: lazy(() =>
                    import("src/views/Penjualan/PengirimanBarangDetail")
                )
            },
            {
                path: "/penjualan/pengirimanbarang/:id/:tab",
                exact: true,
                component: lazy(() =>
                    import("src/views/Penjualan/PengirimanBarangDetail")
                )
            },
            {
                path: "/pembelian/tipesupplier",
                exact: true,
                component: lazy(() =>
                    import("src/views/Pembelian/MasterTipeSupplier")
                )
            },
            {
                path: "/pembelian/tipesupplier/:id",
                exact: true,
                component: lazy(() =>
                    import("src/views/Pembelian/MasterTipeSupplierDetail")
                )
            },
            {
                path: "/pembelian/tipesupplier/:id/:tab",
                exact: true,
                component: lazy(() =>
                    import("src/views/Pembelian/MasterTipeSupplierDetail")
                )
            },
            {
                path: "/pembelian/supplier",
                exact: true,
                component: lazy(() =>
                    import("src/views/Pembelian/MasterSupplier")
                )
            },
            {
                path: "/pembelian/supplier/:id",
                exact: true,
                component: lazy(() =>
                    import("src/views/Pembelian/MasterSupplierDetail")
                )
            },
            {
                path: "/pembelian/supplier/:id/:tab",
                exact: true,
                component: lazy(() =>
                    import("src/views/Pembelian/MasterSupplierDetail")
                )
            },
            {
                path: "/pembelian/pesananpembelian",
                exact: true,
                component: lazy(() =>
                    import("src/views/Pembelian/PesananPembelian")
                )
            },
            {
                path: "/pembelian/pesananpembelian/create",
                exact: true,
                component: lazy(() =>
                    import("src/views/Pembelian/PesananPembelianCreate")
                )
            },
            {
                path: "/pembelian/pesananpembelian/:id",
                exact: true,
                component: lazy(() =>
                    import("src/views/Pembelian/PesananPembelianDetail")
                )
            },
            {
                path: "/pembelian/pesananpembelian/:id/:tab",
                exact: true,
                component: lazy(() =>
                    import("src/views/Pembelian/PesananPembelianDetail")
                )
            },
            {
                path: "/pembelian/penerimaanbarang",
                exact: true,
                component: lazy(() =>
                    import("src/views/Pembelian/PenerimaanBarang")
                )
            },
            {
                path: "/pembelian/penerimaanbarang/create",
                exact: true,
                component: lazy(() =>
                    import("src/views/Pembelian/PenerimaanBarangCreate")
                )
            },
            {
                path: "/pembelian/penerimaanbarang/:id",
                exact: true,
                component: lazy(() =>
                    import("src/views/Pembelian/PenerimaanBarangDetail")
                )
            },
            {
                path: "/pembelian/penerimaanbarang/:id/:tab",
                exact: true,
                component: lazy(() =>
                    import("src/views/Pembelian/PenerimaanBarangDetail")
                )
            },
            {
                path: "/penjualan/fakturpenjualan",
                exact: true,
                component: lazy(() =>
                    import("src/views/Penjualan/FakturPenjualan")
                )
            },
            {
                path: "/penjualan/fakturpenjualan/laporan/:tipe/:dari/:sampai",
                exact: true,
                component: lazy(() =>
                    import("src/views/Penjualan/LaporanPenjualan")
                )
            },
            {
                path: "/penjualan/fakturpenjualan/create",
                exact: true,
                component: lazy(() =>
                    import("src/views/Penjualan/FakturPenjualanCreate")
                )
            },
            {
                path: "/penjualan/fakturpenjualan/preview/:id",
                exact: true,
                component: lazy(() =>
                    import("src/views/Penjualan/FakturPenjualanPreview")
                )
            },
            {
                path: "/penjualan/fakturpenjualan/:id",
                exact: true,
                component: lazy(() =>
                    import("src/views/Penjualan/FakturPenjualanDetail")
                )
            },
            {
                path: "/penjualan/fakturpenjualan/:id/:tab",
                exact: true,
                component: lazy(() =>
                    import("src/views/Penjualan/FakturPenjualanDetail")
                )
            },
            {
                path: "/pembelian/fakturpembelian",
                exact: true,
                component: lazy(() =>
                    import("src/views/Pembelian/FakturPembelian")
                )
            },
            {
                path: "/pembelian/fakturpembelian/create",
                exact: true,
                component: lazy(() =>
                    import("src/views/Pembelian/FakturPembelianCreate")
                )
            },
            {
                path: "/pembelian/fakturpembelian/:id",
                exact: true,
                component: lazy(() =>
                    import("src/views/Pembelian/FakturPembelianDetail")
                )
            },
            {
                path: "/pembelian/fakturpembelian/:id/:tab",
                exact: true,
                component: lazy(() =>
                    import("src/views/Pembelian/FakturPembelianDetail")
                )
            },
            {
                path: "/pembelian/outstandingpo",
                exact: true,
                component: lazy(() =>
                    import("src/views/Pembelian/OutstandingPO")
                )
            },
            {
                path: "/penjualan/outstandingso",
                exact: true,
                component: lazy(() =>
                    import("src/views/Penjualan/OutstandingSO")
                )
            },
            {
                path: "/penjualan/monitorpengiriman",
                exact: true,
                component: lazy(() =>
                    import("src/views/Penjualan/MonitorPengiriman")
                )
            },
            {
                path: "/pembelian/monitorpenerimaan",
                exact: true,
                component: lazy(() =>
                    import("src/views/Pembelian/MonitorPenerimaan")
                )
            },
            {
                path: "/persediaan/kategori",
                exact: true,
                component: lazy(() =>
                    import("src/views/Persediaan/MasterKategori")
                )
            },
            {
                path: "/persediaan/kategori/:id",
                exact: true,
                component: lazy(() =>
                    import("src/views/Persediaan/MasterKategoriDetail")
                )
            },
            {
                path: "/persediaan/kategori/:id/:tab",
                exact: true,
                component: lazy(() =>
                    import("src/views/Persediaan/MasterKategoriDetail")
                )
            },
            {
                path: "/persediaan/subkategori",
                exact: true,
                component: lazy(() =>
                    import("src/views/Persediaan/MasterSubKategori")
                )
            },
            {
                path: "/persediaan/subkategori/:id",
                exact: true,
                component: lazy(() =>
                    import("src/views/Persediaan/MasterSubKategoriDetail")
                )
            },
            {
                path: "/persediaan/subkategori/:id/:tab",
                exact: true,
                component: lazy(() =>
                    import("src/views/Persediaan/MasterSubKategoriDetail")
                )
            },
            {
                path: "/persediaan/barang",
                exact: true,
                component: lazy(() =>
                    import("src/views/Persediaan/MasterBarang")
                )
            },
            {
                path: "/persediaan/barang/:id",
                exact: true,
                component: lazy(() =>
                    import("src/views/Persediaan/MasterBarangDetail")
                )
            },
            {
                path: "/persediaan/barang/:id/:tab",
                exact: true,
                component: lazy(() =>
                    import("src/views/Persediaan/MasterBarangDetail")
                )
            },
            {
                path: "/persediaan/lokasi",
                exact: true,
                component: lazy(() =>
                    import("src/views/Persediaan/MasterLokasi")
                )
            },
            {
                path: "/persediaan/lokasi/:id",
                exact: true,
                component: lazy(() =>
                    import("src/views/Persediaan/MasterLokasiDetail")
                )
            },
            {
                path: "/persediaan/lokasi/:id/:tab",
                exact: true,
                component: lazy(() =>
                    import("src/views/Persediaan/MasterLokasiDetail")
                )
            },
            {
                path: "/persediaan/barangmasuk",
                exact: true,
                component: lazy(() =>
                    import("src/views/Persediaan/BarangMasuk")
                )
            },
            {
                path: "/persediaan/barangmasuk/create",
                exact: true,
                component: lazy(() =>
                    import("src/views/Persediaan/BarangMasukCreate")
                )
            },
            {
                path: "/persediaan/barangmasuk/:id",
                exact: true,
                component: lazy(() =>
                    import("src/views/Persediaan/BarangMasukDetail")
                )
            },
            {
                path: "/persediaan/barangmasuk/:id/:tab",
                exact: true,
                component: lazy(() =>
                    import("src/views/Persediaan/BarangMasukDetail")
                )
            },
            {
                path: "/persediaan/barangkeluar",
                exact: true,
                component: lazy(() =>
                    import("src/views/Persediaan/BarangKeluar")
                )
            },
            {
                path: "/persediaan/barangkeluar/create",
                exact: true,
                component: lazy(() =>
                    import("src/views/Persediaan/BarangKeluarCreate")
                )
            },
            {
                path: "/persediaan/barangkeluar/:id",
                exact: true,
                component: lazy(() =>
                    import("src/views/Persediaan/BarangKeluarDetail")
                )
            },
            {
                path: "/persediaan/barangkeluar/:id/:tab",
                exact: true,
                component: lazy(() =>
                    import("src/views/Persediaan/BarangKeluarDetail")
                )
            },
            {
                path: "/persediaan/mutasistok",
                exact: true,
                component: lazy(() => import("src/views/Persediaan/MutasiStok"))
            },
            {
                path: "/persediaan/mutasistok/create",
                exact: true,
                component: lazy(() =>
                    import("src/views/Persediaan/MutasiStokCreate")
                )
            },
            {
                path: "/persediaan/mutasistok/:id",
                exact: true,
                component: lazy(() =>
                    import("src/views/Persediaan/MutasiStokDetail")
                )
            },
            {
                path: "/persediaan/mutasistok/:id/:tab",
                exact: true,
                component: lazy(() =>
                    import("src/views/Persediaan/MutasiStokDetail")
                )
            },
            {
                path: "/persediaan/stokopname",
                exact: true,
                component: lazy(() => import("src/views/Persediaan/StokOpname"))
            },
            {
                path: "/persediaan/stokopname/create",
                exact: true,
                component: lazy(() =>
                    import("src/views/Persediaan/StokOpnameCreate")
                )
            },
            {
                path: "/persediaan/stokopname/:id",
                exact: true,
                component: lazy(() =>
                    import("src/views/Persediaan/StokOpnameDetail")
                )
            },
            {
                path: "/persediaan/stokopname/:id/:tab",
                exact: true,
                component: lazy(() =>
                    import("src/views/Persediaan/StokOpnameDetail")
                )
            },
            {
                path: "/management/customers/:id",
                exact: true,
                component: lazy(() =>
                    import("src/views/CustomerManagementDetails")
                )
            },
            {
                path: "/management/customers/:id/:tab",
                exact: true,
                component: lazy(() =>
                    import("src/views/CustomerManagementDetails")
                )
            },
            {
                path: "/management/projects",
                exact: true,
                component: lazy(() => import("src/views/ProjectManagementList"))
            },
            {
                path: "/management/orders",
                exact: true,
                component: lazy(() => import("src/views/OrderManagementList"))
            },
            {
                path: "/management/orders/:id",
                exact: true,
                component: lazy(() =>
                    import("src/views/OrderManagementDetails")
                )
            },
            {
                path: "/dashboard",
                exact: true,
                component: OverviewView
            },
            {
                path: "/presentation",
                exact: true,
                component: PresentationView
            },
            {
                path: "/profile/:id",
                exact: true,
                component: lazy(() => import("src/views/Profile"))
            },
            {
                path: "/profile/:id/:tab",
                exact: true,
                component: lazy(() => import("src/views/Profile"))
            },
            {
                path: "/projects/create",
                exact: true,
                component: lazy(() => import("src/views/ProjectCreate"))
            },
            {
                path: "/projects/:id",
                exact: true,
                component: lazy(() => import("src/views/ProjectDetails"))
            },
            {
                path: "/projects/:id/:tab",
                exact: true,
                component: lazy(() => import("src/views/ProjectDetails"))
            },
            {
                path: "/projects",
                exact: true,
                component: lazy(() => import("src/views/ProjectList"))
            },
            {
                path: "/settings",
                exact: true,
                component: lazy(() => import("src/views/Settings"))
            },
            {
                path: "/settings/:tab",
                exact: true,
                component: lazy(() => import("src/views/Settings"))
            },
            {
                path: "/social-feed",
                exact: true,
                component: lazy(() => import("src/views/SocialFeed"))
            },
            {
                path: "/getting-started",
                exact: true,
                component: lazy(() => import("src/views/GettingStarted"))
            },
            {
                component: () => <Redirect to="/errors/error-404" />
            }
        ]
    }
];
