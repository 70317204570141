/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from "react";
import { Link as RouterLink, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import {
    AppBar,
    Badge,
    Button,
    IconButton,
    Toolbar,
    Hidden,
    Input,
    Link,
    Typography,
    colors,
    Popper,
    Paper,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ClickAwayListener
} from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import PhonelinkSetupOutlinedIcon from "@material-ui/icons/PhonelinkSetupOutlined";
import LockIcon from "@material-ui/icons/LockOutlined";
import NotificationsIcon from "@material-ui/icons/NotificationsOutlined";
import PeopleIcon from "@material-ui/icons/PeopleOutline";
import InputIcon from "@material-ui/icons/Input";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import axios from "src/utils/axios";
import NotificationsPopover from "src/components/NotificationsPopover";
import PricingModal from "src/components/PricingModal";
import { logout } from "src/actions";
import ChatBar from "./ChatBar";

const useStyles = makeStyles(theme => ({
    root: {
        boxShadow: "none"
    },
    flexGrow: {
        flexGrow: 1
    },
    search: {
        backgroundColor: "rgba(255,255,255, 0.1)",
        borderRadius: 4,
        flexBasis: 300,
        height: 36,
        padding: theme.spacing(0, 2),
        display: "flex",
        alignItems: "center"
    },
    searchIcon: {
        marginRight: theme.spacing(2),
        color: "inherit"
    },
    searchInput: {
        flexGrow: 1,
        color: "inherit",
        "& input::placeholder": {
            opacity: 1,
            color: "inherit"
        }
    },
    searchPopper: {
        zIndex: theme.zIndex.appBar + 100
    },
    searchPopperContent: {
        marginTop: theme.spacing(1)
    },
    trialButton: {
        marginLeft: theme.spacing(2),
        color: theme.palette.common.white,
        backgroundColor: colors.green[600],
        "&:hover": {
            backgroundColor: colors.green[900]
        }
    },
    trialIcon: {
        marginRight: theme.spacing(1)
    },
    menuButton: {
        marginRight: theme.spacing(1)
    },
    chatButton: {
        marginLeft: theme.spacing(1)
    },
    notificationsButton: {
        marginLeft: theme.spacing(1)
    },
    notificationsBadge: {
        backgroundColor: colors.orange[600]
    },
    logoutButton: {
        marginLeft: theme.spacing(1)
    },
    logoutIcon: {
        marginRight: theme.spacing(1)
    }
}));

const popularSearches = [
    "Dashboard",
    "Persediaan: Master Kategori",
    "Persediaan: Master Sub Kategori",
    "Persediaan: Master Barang",
    "Persediaan: Master Lokasi",
    "Persediaan: Barang Masuk",
    "Persediaan: Barang Keluar",
    "Persediaan: Mutasi Stok",
    "Persediaan: Stok Opname",
    "Pembelian: Master Tipe Supplier",
    "Pembelian: Master Supplier",
    "Pembelian: Pesanan Pembelian",
    "Pembelian: Penerimaan Barang",
    "Pembelian: Faktur Pembelian",
    "Pembelian: Outstanding PO",
    "Pembelian: Monitor Penerimaan",
    "Penjualan: Master Tipe Customer",
    "Penjualan: Master Customer",
    "Penjualan: Pesanan Penjualan",
    "Penjualan: Pengiriman Barang",
    "Penjualan: Faktur Penjualan",
    "Penjualan: Outstanding SO",
    "Penjualan: Monitor Pengiriman"
];

function TopBar({ onOpenNavBarMobile, className, ...rest }) {
    const classes = useStyles();
    const history = useHistory();
    const searchRef = useRef(null);
    const session = useSelector(state => state.session);
    const dispatch = useDispatch();
    const notificationsRef = useRef(null);
    const [openSearchPopover, setOpenSearchPopover] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [notifications, setNotifications] = useState([]);
    const [openNotifications, setOpenNotifications] = useState(false);
    const [openChatBar, setOpenChatBar] = useState(false);
    const [pricingModalOpen, setPricingModalOpen] = useState(false);
    const [viewSearches, setViewSearches] = useState(popularSearches);

    const handleLogout = () => {
        history.push("/auth/login");
        dispatch(logout());
    };

    const handlePricingModalOpen = () => {
        setPricingModalOpen(true);
    };

    const handlePricingModalClose = () => {
        setPricingModalOpen(false);
    };

    const handleChatBarOpen = () => {
        setOpenChatBar(true);
    };

    const handleChatBarClose = () => {
        setOpenChatBar(false);
    };

    const handleNotificationsOpen = () => {
        setOpenNotifications(true);
    };

    const handleNotificationsClose = () => {
        setOpenNotifications(false);
    };

    const handleSearchChange = event => {
        setSearchValue(event.target.value);
        // console.log(event.target.value,searchValue);
        if (event.target.value) {
            // console.log(openSearchPopover);
            if (!openSearchPopover) {
                setOpenSearchPopover(true);
            }
            const word = event.currentTarget.value.toLowerCase();
            const newViewSearches = popularSearches.filter(search => {
                return search.toLowerCase().includes(word);
            });
            // console.log(newViewSearches,event.target.value);
            setViewSearches(newViewSearches);
        } else {
            setViewSearches(popularSearches);
            setOpenSearchPopover(false);
        }
    };

    const handleSearchPopverClose = event => {
        // console.log(event.target);
        setOpenSearchPopover(false);
    };

    const goTo = target => {
        // console.log(target);
        const goToMap = {
            Dashboard: "/dashboard",
            "Persediaan: Master Kategori": "/persediaan/kategori",
            "Persediaan: Master Sub Kategori": "/persediaan/subkategori",
            "Persediaan: Master Barang": "/persediaan/barang",
            "Persediaan: Master Lokasi": "/persediaan/lokasi",
            "Persediaan: Barang Masuk": "/persediaan/barangmasuk",
            "Persediaan: Barang Keluar": "/persediaan/barangkeluar",
            "Persediaan: Mutasi Stok": "/persediaan/mutasistok",
            "Persediaan: Stok Opname": "/persediaan/stokopname",
            "Pembelian: Master Tipe Supplier": "/pembelian/tipesupplier",
            "Pembelian: Master Supplier": "/pembelian/supplier",
            "Pembelian: Pesanan Pembelian": "/pembelian/pesananpembelian",
            "Pembelian: Penerimaan Barang": "/pembelian/penerimaanbarang",
            "Pembelian: Faktur Pembelian": "/pembelian/fakturpembelian",
            "Pembelian: Outstanding PO": "/pembelian/outstandingpo",
            "Pembelian: Monitor Penerimaan": "/pembelian/monitorpenerimaan",
            "Penjualan: Master Tipe Customer": "/penjualan/tipecustomer",
            "Penjualan: Master Customer": "/penjualan/customer",
            "Penjualan: Pesanan Penjualan": "/penjualan/pesananpenjualan",
            "Penjualan: Pengiriman Barang": "/penjualan/pengirimanbarang",
            "Penjualan: Faktur Penjualan": "/penjualan/fakturpenjualan",
            "Penjualan: Outstanding SO": "/penjualan/outstandingso",
            "Penjualan: Monitor Pengiriman": "/penjualan/monitorpengiriman"
        };
        history.push(goToMap[target]);
        setSearchValue("");
        setOpenSearchPopover(false);
        // return <Redirect to={goToMap[target]} />
    };

    useEffect(() => {
        let mounted = true;
        // console.log('local',localStorage.getItem('user').username);
        // const fetchNotifications = () => {
        //   axios.get('/api/account/notifications').then((response) => {
        //     if (mounted) {
        //       setNotifications(response.data.notifications);
        //     }
        //   });
        // };

        // fetchNotifications();
        // setViewSearches(popularSearches);
        // console.log('session',session.loggedIn);
        return () => {
            mounted = false;
        };
    }, []);

    return (
        <AppBar
            {...rest}
            className={clsx(classes.root, className)}
            color="primary"
        >
            {session.loggedIn ? "" : <Redirect to="/auth/login" />}
            <Toolbar>
                <Hidden lgUp>
                    <IconButton
                        className={classes.menuButton}
                        color="inherit"
                        onClick={onOpenNavBarMobile}
                    >
                        <MenuIcon />
                    </IconButton>
                </Hidden>

                <Hidden mdDown>
                    <Typography component="h2" variant="h3" color="inherit">
                        Toko Pelangi
                    </Typography>
                </Hidden>
                <div className={classes.flexGrow} />
                <div className={classes.search} ref={searchRef}>
                    <SearchIcon className={classes.searchIcon} />
                    <Input
                        className={classes.searchInput}
                        disableUnderline
                        onChange={handleSearchChange}
                        placeholder="Search module"
                        value={searchValue}
                    />
                </div>
                <Popper
                    anchorEl={searchRef.current}
                    className={classes.searchPopper}
                    open={openSearchPopover}
                    transition
                >
                    <ClickAwayListener onClickAway={handleSearchPopverClose}>
                        <Paper
                            className={classes.searchPopperContent}
                            elevation={3}
                        >
                            <List>
                                {viewSearches.map(search => (
                                    <ListItem
                                        button
                                        key={search}
                                        onClick={() => goTo(search)}
                                    >
                                        <ListItemIcon>
                                            <SearchIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={search} />
                                    </ListItem>
                                ))}
                            </List>
                        </Paper>
                    </ClickAwayListener>
                </Popper>
                {/* <Button
            className={classes.trialButton}
            onClick={handlePricingModalOpen}
            variant="contained"
          >
            <LockIcon className={classes.trialIcon} />
            Trial expired
          </Button> */}
                {/* <IconButton
          className={classes.chatButton}
          color="inherit"
          onClick={handleChatBarOpen}
        >
          <Badge
            badgeContent={6}
            color="secondary"
          >
            <PeopleIcon />
          </Badge>
        </IconButton>
          <IconButton
            className={classes.notificationsButton}
            color="inherit"
            onClick={handleNotificationsOpen}
            ref={notificationsRef}
          >
            <Badge
              badgeContent={notifications.length}
              classes={{ badge: classes.notificationsBadge }}
              variant="dot"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton> */}
                <Button
                    className={classes.logoutButton}
                    color="inherit"
                    onClick={handleLogout}
                >
                    {`${session.user.username}`}
                    <PhonelinkSetupOutlinedIcon />
                </Button>
            </Toolbar>
            <NotificationsPopover
                anchorEl={notificationsRef.current}
                notifications={notifications}
                onClose={handleNotificationsClose}
                open={openNotifications}
            />
            <PricingModal
                onClose={handlePricingModalClose}
                open={pricingModalOpen}
            />
            <ChatBar onClose={handleChatBarClose} open={openChatBar} />
        </AppBar>
    );
}

TopBar.propTypes = {
    className: PropTypes.string,
    onOpenNavBarMobile: PropTypes.func
};

export default TopBar;
